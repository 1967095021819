import React, { useEffect, useState } from "react";
import { firestore } from "../../../firebase";
import "../css/panel.css";

const Siparisler = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    firestore
      .collection(`hot-sıfır-bir`)
      .doc("orders")
      .onSnapshot((doc) => {
        if (doc.exists) {
          let orders = Object.entries(doc.data());
          setOrders(orders.filter((o) => o[1].status == 2).map((o) => o[1]));
        }
      });
  }, []);
  return (
    <div className="container-fluid w-75" style={{ marginLeft: "17%" }}>
      <div className="row">
        <div className="col-sm-12">
          <div className="white-box">
            <h3 className="box-title"></h3>

            <div className="table-responsive">
              <table
                className="display table text-nowrap"
                id="example"
                cellspacing="0"
                width="100%"
              >
                <thead>
                  <tr>
                    <th className="border-top-0">#</th>
                    <th className="border-top-0">İsim</th>
                    <th className="border-top-0">Soyisim</th>
                    <th className="border-top-0">Adres</th>
                    <th className="border-top-0">Telefon</th>
                    <th className="border-top-0">Sipariş</th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.map((order, i) => (
                    <tr>
                      <td>
                        <b>{i + 1}</b>
                      </td>
                      <td>{order.firstname}</td>
                      <td>{order.lastname}</td>
                      <td>{order.address1}</td>
                      <td>{order.phone}</td>
                      <td>
                        {order.cart.map((item) => {
                          return (
                            <>
                              {item.quantity} x {item.name}
                            </>
                          );
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div class="pagination">
              <a href="#">&laquo;</a>
              <a class="active" href="#">
                1
              </a>
              <a href="#">2</a>
              <a href="#">3</a>
              <a href="#">4</a>
              <a href="#">5</a>
              <a href="#">6</a>
              <a href="#">&raquo;</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Siparisler;
