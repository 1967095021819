import React, { useEffect, useState } from "react";
import { Route, Router, Switch } from "react-router";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { firestore } from "../../firebase";
import About from "./About";
import Cart from "./Cart";
import Checkout from "./Checkout";
import Login from "./Login";
import Main from "./Main";
import Menu from "./Menu";
import Thank from "./Thank";
import Thank2 from "./Thank2";
const Index = () => {
  const [menu, setMenu] = useState([]);
  const [categories, setCategories] = useState([]);
  const [popular, setPop] = useState([]);

  useEffect(() => {
    firestore
      .collection("hot-sıfır-bir")
      .doc("menu")
      .get()
      .then((doc) => {
        let menu = doc.data().Menu;
        setMenu(menu);
        setCategories(
          menu.map((cat) => ({
            id: cat.id,
            name: cat.name,
            image: cat.image,
            sortOrder: cat.sortOrder,
            className: cat.className,
          }))
        );
        let pop = menu.map((me) =>
          me.Meals.map((item) => {
            if (item.isPop) return item;
            else return null;
          })
        );
        setPop([].concat.apply([], pop).filter((f) => f != null));
      });
  }, []);

  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("cart")) || []
  );
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);


  const AddToCart = (item) => {
    let cartCopy = [...cart];
    let { id } = item;

    let existingItem = cartCopy.find((cartItem) => cartItem.id == id);

    if (existingItem) {
      existingItem.quantity += 1; //update item
    } else {
      cartCopy.push({ ...item, quantity: 1 });
    }

    setCart(cartCopy);
  };
  const RemoveFromCart = (obj) => {
    let new_cart = cart;
    cart.forEach((item, i, new_cart) => {
      if (item.id == obj.id) {
        new_cart[i].quantity -= 1;
      }
    });
    setCart(new_cart.filter((f) => f.quantity != 0));
  };
  const DeleteFromCart = (item) => {
    setCart(cart.filter((f) => f.id != item.id));
  }
  const RemoveCart = () => {
    setCart([]);
  }
  return (
    <div>
      <div className="wrapper">
        <Header menu={menu} categories={categories} cart={cart} />

        <Route path="/hakkimizda">
          <About />
        </Route>
        <Route path="/hesap">
          <Login />
        </Route>
        <Route exact path="/">
          <Main menu={menu} categories={categories} cart={cart} AddToCart={AddToCart} RemoveFromCart={RemoveFromCart} popular={popular} />
        </Route>
        <Route exact path="/menu">
          <Menu menu={menu} categories={categories} cart={cart} AddToCart={AddToCart} RemoveFromCart={RemoveFromCart} />
        </Route>
        <Route path="/sepet">
          <Cart menu={menu} categories={categories} cart={cart} AddToCart={AddToCart} RemoveFromCart={RemoveFromCart} DeleteFromCart={DeleteFromCart} />
        </Route>

        <Route path="/siparis">
          <Checkout cart={cart} AddToCart={AddToCart} RemoveFromCart={RemoveFromCart} RemoveCart={RemoveCart} />
        </Route>
        <Route path="/onay">
          <Thank />
        </Route>
        <Route path="/email-onay">
          <Thank2 />
        </Route>
        <Footer />
      </div>
    </div>
  );
};

export default Index;


/*
<Route path="/menu">
          <Menu menu={menu} categories={categories} />
        </Route>

        <Route path="/hakkimizda">
          <About />
        </Route>
        <Route path="/hesap">
          <Login />
        </Route>
*/