import "./Footer.css";
import Payment from "../../assets/images/payment.png";
import { useState } from 'react';
import { firestore } from '../../firebase';
import { useHistory } from "react-router";

const Footer = () => {

  const [email, setEmail] = useState("");
  const history = useHistory();
  return (
    <footer>
      <div className="main_footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="M_footer">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 quick_links">
                  <div className="main_cnt">
                    <ul>
                      <li>
                        <a href="/" className="trans" title="Login">
                          Ana Sayfa
                        </a>
                      </li>
                      <li>
                        <a href="/hakkimizda" className="trans" title="Login">
                          Hakkımızda
                        </a>
                      </li>
                      <li>
                        <a href="/sepet" className="trans" title="Login">
                          Sepet
                        </a>
                      </li>
                      <li>
                        <a href="/hesap" className="trans" title="Login">
                          Hesap
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-6 col-xs-12 location">
                  <div className="title">Konumumuz</div>
                  <div className="main_cnt">
                    {" "}
                    <span className="address">
                      <a target="_blank" href="https://g.page/hotsifirb1rkebap?share">
                        Beşirli Devlet Sahil Yolu Cad. Orman Bölge Müdürlüğü Yanı
                        Ortahisar/Trabzon
                      </a>
                    </span>{" "}
                    <span className="time1">Her Gün: 11:30–22:00</span>{" "}
                    <span className="conact">
                      <a href="tel:04622301010">0(462) 230 10 10</a>
                      <br />
                      <a href="tel:04622291010">0(462) 229 10 10</a>
                      <br />
                    </span>{" "}
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 subscribe">
                  <div className="title">Abone Olun</div>
                  <div className="main_cnt">
                    <p className="sbcr_label">
                      Kampanyalardan ve özel indirimlerden haberdar olmak için
                      abone olun.
                    </p>
                    <form id="subscribeform">
                      <div className="sbcrb_input">
                        <input
                          type="text"
                          name="subscribeemail"
                          className="form-control"
                          placeholder="Email adresinizi giriniz."
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <button
                          className="subscribebutton send"
                          name="getbutton"
                          onClick={(e) => {
                            e.preventDefault();
                            firestore.collection("hot-sıfır-bir").doc("aboneler")
                              .set({ email }, { merge: true }); history.push('email-onay');
                          }}
                        ></button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="F_footer" style={{ background: "#ffffff" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="payment" style={{ color: "black" }}>
                {" "}
                <span>Ödeme Seçenekleri</span>{" "}
                <span className="pay_cards">
                  <img src={Payment} alt="" width="50%" />
                </span>
              </div>
              <div className="social">
                <ul>
                  <li>
                    <a href="#" className="trans" title="Facebook">
                      <i class="fa fa-facebook-square" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="trans" title="Instagram">
                      <i className="fa fa-instagram" aria-hidden="true">Instagram</i>
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <p>
            ©{" "}
            <a href="#" className="trans" title="">
              DBA Teknoloji
            </a>{" "}
            <script type="text/javascript">
              document.write(new Date().getFullYear());
            </script>
            , Bütün Hakları Saklıdır{" "}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
