import "./Popular.css";
import React, { useState } from "react";

import { toast } from "react-toastify";

const Popular = ({ cart, AddToCart, RemoveFromCart, popular }) => {

  const [popup, setPopup] = useState({ display: "none", opacity: 0 });
  const [checked1, setChecked1] = useState();
  const [checked2, setChecked2] = useState();

  const addToCart = (item) => {
    AddToCart(item);

    toast.success("Sepete Eklendi!", {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <div className="options-popup" style={popup}>
        <div class="popup">
          <a
            class="close"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPopup({ display: "none" });
            }}
          >
            &times;
          </a>
          {popup.display != "none" && (
            <>
              {popup?.item?.addOptions?.length > 1 && (
                <div className="options">
                  <p>Eklenecek ürünleri seçiniz:</p>
                  <div className="box mt-2">
                    <div class="PT_optn">
                      <ul class="PT-radio">
                        {popup?.item.addOptions
                          ?.sort((a, b) => {
                            if (a.sortOrder < b.sortOrder) return -1;
                            else return 1;
                          })
                          .map((opt, i) => {
                            return (
                              <li>
                                <input
                                  type="radio"
                                  name="radio-group-1"
                                  id={"radio-" + i}
                                  checked={checked1["radio-" + i]}
                                />
                                <label for={"radio-" + i}>{opt.name}</label>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {popup?.item?.removeOptions?.length > 1 && (
                <div className="options">
                  <p>Çıkarılacak ürünleri seçiniz:</p>
                  <div className="box mt-2">
                    <div class="PT_optn">
                      <ul class="PT-radio" onChange={() => { setChecked2({}) }} >
                        {popup.item.removeOptions
                          ?.sort((a, b) => {
                            if (a.sortOrder < b.sortOrder) return -1;
                            else return 1;
                          })
                          .map((opt, i) => {
                            return (
                              <li>
                                <input
                                  type="radio"
                                  name="radio-group-2"
                                  id={"radio-2" + i}
                                  checked={checked2["radio-2" + i]}
                                />
                                <label for={"radio-2" + i}>{opt.name}</label>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <section>
        <div className="popular">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main_popular">
                  <h2 className="title">Popülerler</h2>
                  <p className="desc">

                  </p>
                  <div className="p_card">
                    <div className="row">
                      {popular?.map((item, i) => (
                        <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6 ">
                          <div className="P_itmesbox">
                            <div className="PT_image">
                              <img
                                src={item.image}
                                className="absoImg"
                                alt=""
                              />
                            </div>
                            <div className="PT_dscr">
                              <h3 className="PT_title">{item.name}</h3>
                              <p className="PT_dtls">{item.description}</p>

                              <div className="price_block">
                                <div className="price">₺ {item.price}</div>
                                <a
                                  className="card_btn"
                                  onClick={() => {
                                    addToCart(item);
                                    /*setPopup({
                                      display: "block",
                                      opacity: 1,
                                      item,
                                    });
                                    */
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  Sepete Ekle
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Popular;
