import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, useHistory } from "react-router-dom";

import Sidebar from "./Sidebar";
import Menu from "./Menu/Menu";
import GelenSiparisler from "./Siparisler/GelenSiparisler";
import Siparisler from "./Gecmis/Siparisler";
import { auth, firestore } from "../../firebase";
import Audio from "../../assets/notification.mp3";

const Panel = () => {
  const [active, setActive] = useState();
  const [orders, setOrders] = useState();

  useEffect(() => {
    firestore
      .collection("hot-sıfır-bir")
      .doc("info")
      .get()
      .then((doc) => {
        setActive(doc.data().active);
      });
    firestore
      .collection("hot-sıfır-bir/x/orders")
      .where("status", "<", 3)
      .get()
      .then((doc) => {
        setOrders(doc.docs.map(d => d.data()));
      });
    firestore
      .collection("hot-sıfır-bir/x/orders")
      .where("status", "<", 3)
      .onSnapshot((doc) => {
        setOrders(doc.docs.map(d => d.data()));
        doc.docChanges().forEach(change => {
          if (change.type == "added") {
            document.getElementsByClassName("audio-element")[0].play();
          }
        })
        setOrders(doc.docs.map(d => d.data()));
      });
  }, []);
  const handleCheckbox = () => {
    firestore
      .collection("hot-sıfır-bir")
      .doc("info")
      .set({ active: !active })
      .then(() => {
        localStorage.setItem("active", !active);
        setActive(!active);
      });
  };
  const history = useHistory();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        history.push("/admin/giris");
      }
    });
  }, []);
  return (
    <BrowserRouter>
      <audio className="audio-element">
        <source src={Audio}></source>
      </audio>
      <Sidebar active={active} handleCheckbox={handleCheckbox} />
      {active != (null || undefined) && (
        <>
          <Route path="/admin/menu" component={Menu} />
          <Route
            path="/admin/siparisler"
            render={() => <GelenSiparisler active={active} orders={orders} />}
          />
          <Route path="/admin/gecmis" component={Siparisler} />{" "}
        </>
      )}
    </BrowserRouter>
  );
};

export default Panel;

/*

 <Route path="/gecmis">
          <GelenSiparisler />
        </Route>
*/
