import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { firestore } from "../../../firebase";
import Logo from "../../../assets/images/logo-1-nobg.png";
import "../../../assets/css/print.css";

const Print = () => {
  const { id } = useParams();
  const [order, setOrder] = useState();
  const history = useHistory();
  const [loading, done] = useState(false);

  useEffect(() => {
    firestore
      .collection("hot-sıfır-bir/x/orders")
      .doc(id)
      .get()
      .then((doc) => {
        setOrder(doc.data());
      });
  }, []);

  const calculateTotal = () => {
    let total = 0.0;
    order?.cart.map((item) => (total += item.price * item.quantity));
    return total;
  };
  useEffect(() => {
    if (order && loading) {
      window.print();
      history.push("/admin/siparisler");
    }
  });

  return (
    <div class="ticket">
      <img src={Logo} style={{ marginTop: 20, marginBottom: 20 }} alt="Logo" onLoad={() => done(true)} />
      <p class="centered">{order?.firstname + " " + order?.lastname}</p>
      <p class="centered">{order?.address1}</p>
      <p class="centered">{order?.address2}</p>
      <p class="centered">{order?.phone}</p>
      <table>
        <thead>
          <tr>
            <th className="quantity">Adet</th>
            <th className="description">Ürün</th>
            <th className="price">Fiyat</th>
          </tr>
        </thead>
        <tbody style={{ borderBottom: "1px solid black" }}>
          {order?.cart.map((item) => (
            <tr>
              <td className="quantity">{item.quantity ? item.quantity : 1}</td>
              <td className="description">{item.name}</td>
              <td className="price">{item.price} ₺</td>
            </tr>
          ))}
        </tbody>
        <tr>
          <td>Toplam:</td>
          <td></td>
          <td>{calculateTotal()} ₺</td>
        </tr>
      </table>
      <p className="centered">
        Tercihiniz için teşekkürler!
        <br /> AlkaQr Menu
        <br /> info@dbateknoloji.com{" "}
      </p>
    </div>
  );
};

export default Print;
