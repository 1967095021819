import React, { useEffect, useState } from "react";
import "./css/style.css";

import { useHistory, Link } from "react-router-dom";
import { auth, firestore } from "../../firebase";
import logo from "../../assets/images/logo-1-nobg.png";

const Sidebar = ({ active, handleCheckbox }) => {
  const [orderCount, setOrderCount] = useState();
  const history = useHistory();
  const signOut = () => {
    auth.signOut().then(() => {
      history.push("/admin/giris");
    });
  };
  useEffect(() => {
    firestore.collection('hot-sıfır-bir/x/orders')
      .where('status', '==', 0)
      .onSnapshot(doc => {
        setOrderCount(doc.docs.length);
      })
  }, [])
  return (
    <div
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin5"
      data-sidebartype="full"
      data-sidebar-position="absolute"
      data-header-position="absolute"
      data-boxed-layout="full"
    >
      <header className="topbar">
        <nav className="navbar top-navbar ">
          <div className="navbar-header" data-logobg="skin6">
            <a className="navbar-brand ml-5" href="#">
              <b className="logo-icon ">
                <img src={logo} alt="homepage" width="40%" />
              </b>
            </a>
          </div>
        </nav>
      </header>

      <aside className="left-sidebar" data-sidebarbg="skin6">
        <div className="scroll-sidebar">
          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              <li className="sidebar-item pt-2">
                <Link
                  className="sidebar-link waves-effect waves-dark sidebar-link"
                  to="/admin"
                  aria-expanded="false"
                >
                  <i className="far fa-clock" aria-hidden="true"></i>
                  <span className="hide-menu">Ana Sayfa</span>
                </Link>
              </li>
              <li className="sidebar-item pt-2">
                <Link
                  className="sidebar-link waves-effect waves-dark sidebar-link"
                  to="/admin/siparisler"
                  aria-expanded="false"
                >
                  <i className=" fab fa-microsoft" aria-hidden="true"></i>
                  <span className="hide-menu">Gelen Siparişler <span style={{ color: 'red', boxShadow: '1 1 5px red', marginLeft: 10 }}>{orderCount}</span></span>
                </Link>
              </li>
              <li className="sidebar-item pt-2">
                <Link
                  className="sidebar-link waves-effect waves-dark sidebar-link"
                  to="/admin/menu"
                  aria-expanded="false"
                >
                  <i className="fab fa-leanpub" aria-hidden="true"></i>
                  <span className="hide-menu">Menü</span>
                </Link>
              </li>
              <li className="sidebar-item pt-2">
                <Link
                  className="sidebar-link waves-effect waves-dark sidebar-link"
                  onClick={handleCheckbox}
                  aria-expanded="false"
                >
                  {active ? (<>
                    <i style={{ color: "#5CFF5C" }} className="fas fa-bell" aria-hidden="true"></i>
                    <span className="hide-menu">Yoğunluğa Al</span></>) :
                    (<>
                      <i style={{ color: "#FF5C5C" }} className="fas fa-bell-slash" aria-hidden="true"></i>
                      <span className="hide-menu">Aktif Et</span></>)
                  }

                </Link>
              </li>
              <li className="sidebar-item pt-2">
                <Link
                  className="sidebar-link waves-effect waves-dark sidebar-link"
                  onClick={signOut}
                  aria-expanded="false"
                >
                  <i className="fa fa-sign-out-alt" aria-hidden="true"></i>
                  <span className="hide-menu">Çıkış Yap</span>
                </Link>
              </li>


              {/* <div className="row" style={{ position:'absolute', top: "77vh", 
              left: "1vw",borderRadius: 10 }}>
                          <div className="toggle">
                        <input type="checkbox" 
                        onClick={handleCheckbox} id="toggle" checked={active}/>
                        <label for="toggle"></label>
                      </div>
               </div>   
                */}

              {/* <li className="sidebar-item pt-2">
                <Link
                  className="sidebar-link waves-effect waves-dark sidebar-link"
                  to="/admin/gecmis"
                  aria-expanded="false"
                >
                  <i className=" far fa-chart-bar" aria-hidden="true"></i>
                  <span className="hide-menu">Geçmiş Siparişler</span>
                </Link>
  </li>*/}
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
