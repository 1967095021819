import React, { useState, useEffect } from "react";
import { firestore } from '../../firebase';

const About = () => {
  const [texts, setTexts] = useState("");
  useEffect(() => {
    firestore.
      collection('hot-sıfır-bir')
      .doc("info")
      .get()
      .then(doc => {
        setTexts({ orderText: doc.data().orderText, aboutUs: doc.data().aboutUs })
      });
  }, []);
  return (
    <>
      <div class="H_banner">
        <div class="main_pitch">
          <h1 class="page_title">Hakkımızda</h1>
        </div>
      </div>
      <div class="breadcum">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="main_breadcum">
                <ul>
                  <li>
                    <a href="/" class="trans" title="Home">
                      Ana Sayfa
                    </a>
                  </li>
                  <li>Hakkımızda</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="history_sction">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main_history">
                  <h2 className="title">Hot Sıfır Bir</h2>
                  <div className="desc">
                    <p>
                      {texts?.aboutUs}
                    </p>

                  </div>

                  {/*<div className="points_history">
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <ul>
                          <li className="icon1">
                            <a className="trans" title="Delivery or Takeaway">
                              <span>Lorem ipsum</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <ul>
                          <li className="icon2">
                            <a className="trans" title="Secure card payment">
                              <span>Lorem ipsum</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <ul>
                          <li className="icon3">
                            <a className="trans" title="Cash payment">
                              <span>Lorem ipsum</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <ul>
                          <li className="icon4">
                            <a className="trans" title="Delivery or Takeaway">
                              <span>Lorem ipsum</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="Order">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main_Order">
                  <h2 className="title">Online Sipariş Verin</h2>

                  <p className="desc">
                    {texts?.orderText}
                  </p>
                  <div className="order_button">
                    <a
                      href="/menu"
                      className="red_btn trans hvr-ripple-out"
                      title="Order Now"
                    >
                      Menü
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
