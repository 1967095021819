import "./Banner.css";

import { useState } from "react";

const Banner = () => {
  return (
    <div className="hwork" id="banner-desktop">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="main_hwork">
              <ul>
                <li className="order">
                  <div className="title">
                 <p style={{fontWeight: 'bold',fontSize: 24}}>Siparişini Ver</p>
                  </div>
                </li>
                <li className="deliver_L">
                  <div className="title">
                  <p style={{fontWeight: 'bold',fontSize: 24}}>Adres Bilgilerini Gir</p>
                  </div>
                </li>
                <li className="deliver_pick">
                  <div className="title">
                  <p style={{fontWeight: 'bold',fontSize: 24}}>Kapına Gelsin</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Banner;
