import firebase, { firestore, auth } from "../../../firebase";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./Siparisler.css";
import { v4 as uuid } from "uuid";
import Order from "./Order";

const GelenSiparisler = ({ active, orders }) => {
  const approveOrder = (id) => {
    let order = orders.filter(o => o.id == id)[0];
    firestore
      .collection(`hot-sıfır-bir/x/orders`)
      .doc(order.id)
      .update({
        status: 1,
      })
      .then(() => {
        fetch(
          `https://yjltp1ft55.execute-api.eu-central-1.amazonaws.com/default/emailer?email=${order.checkoutemail.trim()}&type=0`
        );
      });
  };
  const cancelOrder = (id) => {
    let order = orders.filter(o => o.id == id)[0];
    firestore
      .collection(`hot-sıfır-bir/x/orders`)
      .doc(order.id)
      .update({
        status: 3
      })
      .then(() => {
        fetch(
          `https://yjltp1ft55.execute-api.eu-central-1.amazonaws.com/default/emailer?email=${order.checkoutemail.trim()}&type=2`
        );
      });
  };
  const deliverOrder = (id, type) => {
    let order = orders.filter(o => o.id == id)[0];
    firestore
      .collection(`hot-sıfır-bir/x/orders`)
      .doc(order.id)
      .update({
        status: 2
      })
      .then(() => {
        if (type == "deliver")
          fetch(
            `https://yjltp1ft55.execute-api.eu-central-1.amazonaws.com/default/emailer?email=${order.checkoutemail.trim()}&type=1`
          );
      });
  };
  const notActive = (id) => {
    let order = orders.filter(o => o.id == id)[0];

    firestore
      .collection(`hot-sıfır-bir/x/orders`)
      .doc(order.id)
      .update(
        { status: 4 }
      )
      .then(() => {
        try {
          fetch(
            `https://yjltp1ft55.execute-api.eu-central-1.amazonaws.com/default/emailer?email=${order.checkoutemail.trim()}&type=3`
          );
        } catch { }
      });
  };

  return (
    <>
      <div className="row" style={{ marginLeft: "17%", height: "50%" }}>
        {active != (null || undefined) &&
          orders &&
          orders
            .filter((o) => o.status == 0)
            .map((order) => {
              return (
                <Order
                  order={order}
                  approveOrder={approveOrder}
                  cancelOrder={cancelOrder}
                  notActive={notActive}
                  active={active}
                />
              );
            })}
      </div>
      <div className="row" style={{ marginLeft: "17%", height: "50%" }}>
        {active != (null || undefined) &&
          orders &&
          orders
            .filter((o) => o.status == 1)
            .map((order) => {
              return (
                <Order
                  order={order}
                  approveOrder={approveOrder}
                  cancelOrder={cancelOrder}
                  deliverOrder={deliverOrder}
                  active={active}
                />
              );
            })}
      </div>
    </>
  );
};

export default GelenSiparisler;

/*
 {garson && (
        <Garson masa={garson[0]} visible={true} cafe={cafe} garson={garson} />


        
      )}
*/
