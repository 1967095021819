import React from "react";

const Login = () => {
  return (
    <>
      <section id="login">
        <div class="login_section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="main_login">
                  <div class="login_left col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="login_bogx">
                      <h2 class="title">Giriş Yap</h2>
                      <form id="loginform">
                        <div class="login_form">
                          <ul>
                            <li>
                              <label for="EmailAddress">Email Adresi</label>
                              <input
                                class="form-control"
                                id="EmailAddress"
                                name="emailaddress"
                                title="Email Address"
                                type="email"
                              />
                            </li>
                            <li>
                              <label for="Password">Şifre</label>
                              <input
                                class="form-control"
                                title="Password"
                                id="Password"
                                name="password"
                                type="password"
                              />
                            </li>
                            <li class="half checkbox">
                              <input
                                id="check4"
                                type="checkbox"
                                name="check"
                                value="Bike"
                              />
                              <label for="check4">Beni unutma</label>
                            </li>
                            <li class="half text-right">
                              <a href="#" class="trans">
                                Şifremi Unuttum ?
                              </a>
                            </li>
                            <li>
                              <button
                                type="submit"
                                class="loginbutton red_btn trans squre-btn hvr-ripple-out"
                                name="Login"
                              >
                                Giriş Yap
                              </button>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="signup_right col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="login_box">
                      <h2 class="title">Yeni Hesap Oluştur</h2>
                      <form id="registrationform">
                        <ul>
                          <li>
                            <label for="EmailAddress">Email Adresi</label>
                            <input
                              class="form-control"
                              name="registeremail"
                              title="Email Address"
                              type="email"
                            />
                          </li>
                          <li>
                            <button
                              type="submit"
                              class="register-btn red_btn trans squre-btn hvr-ripple-out"
                              name="Register"
                            >
                              Kayıt ol
                            </button>
                          </li>
                        </ul>
                      </form>
                      <h4 class="title_checklist">Neden kayıt olmalıyım :</h4>
                      <ul class="checklist">
                        <li class="checkbox">
                          <input
                            id="check1"
                            type="checkbox"
                            name="check"
                            value="Bike"
                          />
                          <label for="check1">
                            Sipariş verme İşlemlerini hızlandırın
                          </label>
                        </li>
                        <li class="checkbox">
                          <input
                            id="check2"
                            type="checkbox"
                            name="check"
                            value="Bike"
                          />
                          <label for="check2">
                            Siparişlerinizi kolayca takip edin
                          </label>
                        </li>
                        <li class="checkbox">
                          <input
                            id="check3"
                            type="checkbox"
                            name="check"
                            value="Bike"
                          />
                          <label for="check3">
                            Tüm Sipariş İşlemlerinizin Kaydını Tutun
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="quick_order">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="main_quickorder">
                  <h3 class="title">Bizi arayabilirsiniz</h3>
                  <div class="cntct typewriter-effect">
                    <span class="call_desk">
                      <a href="tel:04622301010" id="typewriter_num">0(462) 230 10 10</a>
                      <br />
                      <a href="tel:04622291010" id="typewriter_num">0(462) 229 10 10</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
