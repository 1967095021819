import React, { useState } from "react";
import Logo from "../../assets/images/logo-1-nobg.png";
import "./Header.css";

const Header = ({ cart }) => {
  const [isOpen, setOpen] = useState(false);

  const getTotal = () => {
    let items = [...cart];
    let total = 0.0;
    items.map((i) => (total += parseFloat(i.price * i.quantity)));
    return total;
  };

  return (
    <header id="Header">
      <div className="main_header">
        <div className="top_nav" id="navbar">
          <div className="">
            <div className="">
              <div className="">
                <div className="logo" style={{ width: "22%", marginLeft: '5%' }}>
                  <a title="Hot Sıfır Bir">
                    <img onClick={() => window.location.href = "/"} style={{ cursor: 'pointer' }} src={Logo} alt="Hot Sıfır Bir" />
                  </a>
                </div>
                <div className="nav_manu mr-5">
                  <div className="Menu_list">
                    <ul className="menu">
                      <li>
                        <a href="/" className="trans hvr-underline-from-left">
                          Ana Sayfa
                        </a>
                      </li>
                      <li>
                        <a href="/hakkimizda" className="trans hvr-underline-from-left">
                          Hakkımızda
                        </a>
                      </li>
                      <li>
                        <a href="/menu" className="trans">
                          Menü
                        </a>
                      </li>

                    </ul>
                  </div>
                  <div className="Cart">
                    <a href="/sepet" title="Cart">
                      <span className="Cart_count">
                        Sepet : ({cart?.length}) ₺{getTotal()}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button id="burger" className={isOpen ? ("open-main-nav is-open") : ("open-main-nav")}
          onClick={() => setOpen(!isOpen)}>
          <span className="burger"></span>
        </button>
      </div>



      <div className="device">
        <nav className={isOpen ? ("main-nav is-open") : ("main-nav")} id="main-nav">
          <div className="logo">
            <a href="/" title="Hot Sıfır Bir">
              <img src={Logo} alt="Hot Sıfır Bir" />
            </a>
          </div>
          <ul>
            <li>
              <a href="/">Ana Ekran</a>
            </li>
            <li>
              <a href="/hakkimizda">Hakkımızda</a>
            </li>
            <li>
              <a href="/menu">Menü</a>
            </li>
            <li>
              <a href="/hesap" className="trans">
                Giriş Yap
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
