import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { firestore } from "../../firebase";
import { v4 as uuid } from "uuid";

const Checkout = ({ cart, AddToCart, RemoveFromCart, RemoveCart }) => {
  const history = useHistory();
  const { selectedOption } = useLocation().state;

  const calculateTotal = () => {
    let items = [...cart];
    let total = 0.0;
    items.map((i) => (total += parseFloat(i.price * i.quantity)));
    return total;
  };

  const giveOrder = (e) => {
    e.preventDefault();
    if (cart.length < 1) { alert("Sepette Ürün Yok!"); return; }

    let check = false;
    for (const [key, value] of Object.entries(values)) {
      if (!(["address2", "notes"].includes(key)) && value.trim() == "") {
        check = true;
      }
    }
    if (check) { alert("Eksik Giriş!"); return; }
    let id = uuid();
    firestore
      .collection(`hot-sıfır-bir/x/orders`)
      .doc(id)
      .set(
        {
          id,
          ...values,
          cart,
          deliveryType: selectedOption,
          status: 0,
          date: new Date().toUTCString(),
        }
      )
      .then(() => {
        RemoveCart();
        history.push("/onay");
      });
  };

  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    checkoutemail: "",
    phone: "",
    address1: "",
    address2: "",
    notes: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <section style={{ marginTop: '7%' }}>
        <div class="main_checkout">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="inner_checkout ">
                  <form id="checkoutform">
                    <div class="left_cnt col-lg-8 col-md-7 col-sm-12 col-xs-12">
                      <h2 class="title">Teslimat Bilgileri</h2>

                      <div class="checkout-form">
                        <ul>
                          <li>
                            <label for="FirstName">
                              İsim<span>*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="FirstName"
                              name="firstname"
                              onChange={handleChange}
                            />
                          </li>
                          <li>
                            <label for="LastName">
                              Soyisim<span>*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              id="LastName"
                              name="lastname"
                              onChange={handleChange}
                            />
                          </li>

                          <li>
                            <label for="EmailAddress">
                              Email<span>*</span>
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              id="EmailAddress"
                              name="checkoutemail"
                              onChange={handleChange}
                            />
                          </li>
                          <li>
                            <label for="PhoneNumber">
                              Telefon Numarası<span>*</span>
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              id="PhoneNumber"
                              name="phone"
                              onChange={handleChange}
                            />
                          </li>
                          <li class="fullwidth">
                            <label for="Address">
                              Addres<span>*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Site Adı, Bina Numarası, Daire"
                              name="address1"
                              id="Address"
                              onChange={handleChange}
                            />
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Cadde, Sokak, Mahalle Adı "
                              name="address2"
                              onChange={handleChange}
                            />
                          </li>

                          <li class="fullwidth">
                            <label for="OrderNotes">Sipariş Notu</label>
                            <textarea
                              class="form-control"
                              id="OrderNotes"
                              name="notes"
                              onChange={handleChange}
                            ></textarea>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="right_cntbar col-lg-4 col-md-5 col-sm-12 col-xs-12">
                      <div class="your_order">
                        <div class="Order_title">
                          <span class="O_lefttitle">Sepet</span>
                        </div>
                        <div class="Order_number">
                          <ul>
                            {cart?.map((item, i) => (
                              <li key={i}>
                                <div
                                  className="Order_number"
                                  onClick={() => RemoveFromCart(item)}
                                >
                                  <div className="Order_names">
                                    <span className="O_name">
                                      {item.quantity}x {item.name}
                                    </span>
                                    <span className="O_type">Extra Large</span>
                                  </div>
                                  <div className="Order_price">
                                    <span className="O_price">
                                      ₺{item.price}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div class="totle_Oamount">
                          <div class="O_totlecost">
                            <span class="O_title">Toplam</span>
                            <span class="O_price">₺{calculateTotal()}</span>
                          </div>
                          <ul>
                            <li>
                              <button
                                class="checkout-btn red_btn trans hvr-float-shadow"
                                name="checkout"
                                onClick={giveOrder}
                              >
                                Onayla
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
