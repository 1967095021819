import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { firestore } from "../../firebase";
import "./Main.css";
import { toast } from "react-toastify";

const Menu = ({ menu, categories }) => {
  const [selected, Select] = useState(useLocation().state?.selected || null);
  const [items, setItems] = useState();
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("cart")) || []
  );
  const [popup, setPopup] = useState({ display: "none", opacity: 0 });
  useEffect(() => {
    if (selected) {
      setItems(menu?.filter((m) => m.id == selected?.id)[0]);
    } else {
      Select(menu[0]);
      setItems(menu[0]);
    }
  }, [menu]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);
  useEffect(() => {
    setItems(menu?.filter((m) => m.id == selected?.id)[0]);
  }, [selected]);

  const toastMessage = (text, type = 0) => {
    if (type == 0)
      toast.success(text, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    else if (type == 1) {
      toast.info(text, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (type == 2) {
      toast.error(text, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }


  const AddToCart = (item) => {
    let cartCopy = [...cart];
    let { id } = item;

    let existingItem = cartCopy.find((cartItem) => cartItem.id == id);

    if (existingItem) {
      existingItem.quantity += 1; //update item
    } else {
      cartCopy.push({ ...item, quantity: 1 });
    }

    setCart(cartCopy);

    toastMessage("Sepete Eklendi!");
  };
  const RemoveFromCart = (obj) => {
    let new_cart = cart;
    cart.forEach((item, i, new_cart) => {
      if (item.id == obj.id) {
        new_cart[i].quantity -= 1;
      }
    });
    setCart(new_cart.filter((f) => f.quantity != 0));
    toastMessage("Sepetten Çıkarıldı!");
  };

  const calculateTotal = () => {
    let items = [...cart];
    let total = 0.0;
    items.map((i) => (total += parseFloat(i.price * i.quantity)));
    return total;
  };

  return (
    <>
      <div className="options-popup" style={popup}>
        <div class="popup">
          <a
            class="close"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPopup({ display: "none" });
            }}
          >
            &times;
          </a>
          asd
        </div>
      </div>

      <div className="H_banner">
        <div className="main_pitch">
          <h1 className="page_title">{selected?.name}</h1>
        </div>
      </div>

      <div className="breadcum">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main_breadcum">
                <ul>
                  <li>
                    <a href="/" className="trans" title="Home">
                      Ana Ekran
                    </a>
                  </li>
                  <li>Menümüz</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="Our_menu">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main_Ourmenu">
                  <h2 className="title">{selected?.name}</h2>
                  <div className="content_menu bottom_pitch">
                    <div className="main_bpitch">
                      <ul className="nav nav-tabs" role="tablist">
                        {categories
                          ?.sort((a, b) => {
                            if (
                              parseFloat(a.sortOrder) < parseFloat(b.sortOrder)
                            )
                              return -1;
                            else return 1;
                          })
                          .map((cat) => (
                            <li
                              style={{ cursor: "pointer" }}
                              className={
                                cat.id == selected?.id
                                  ? `nav-item active ${cat.className}`
                                  : `nav-item ${cat.className}`
                              }
                              onClick={() => {
                                Select(cat);
                              }}
                            >
                              <a
                                className="nav-link  trans"
                                data-toggle="tab"
                                title="Pizza"
                                href={window.matchMedia("only screen and (max-width: 760px)").matches ? "#items" : null}
                              >
                                <span className="icon">{cat.name}</span>
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>

                  <div className="tab-content">
                    <div id="pizza" className=" tab-pane active">
                      <div className="content_row">
                        <div className="left_cntbar">
                          <div className="pizza_items" id="items">
                            <div className="row">
                              {items?.Meals?.sort((a, b) => {
                                if (
                                  parseFloat(a.sortOrder) <
                                  parseFloat(b.sortOrder)
                                )
                                  return -1;
                                else return 1;
                              }).map((item, i) => (
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                                  <div className="P_itmesbox">
                                    <div className="PT_image">
                                      <img
                                        src={item.image}
                                        className="absoImg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="PT_dscr">
                                      <h3 className="PT_title">{item.name}</h3>
                                      <p className="PT_dtls">
                                        {item.description}
                                      </p>

                                      <div className="price_block">
                                        <div className="price">
                                          ₺ {item.price}
                                        </div>
                                        <a
                                          className="card_btn"
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            AddToCart(item);
                                            /*setPopup({
                                              display: "block",
                                              opacity: 1,
                                            });*/
                                          }}
                                        >
                                          Sepete Ekle
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="right_cntbar col-lg-3">
                    <div className="your_order">
                      <div className="Order_title">
                        <span className="O_lefttitle">Sepetiniz</span>
                        <span className="O_cart">
                          <a href="#" className="trans" title="Add To Cart"></a>
                        </span>
                      </div>
                      <div className="Order_number">
                        <ul>
                          {cart?.map((item, i) => (
                            <li key={i}>
                              <div
                                className="Order_number"
                                onClick={() => RemoveFromCart(item)}
                              >
                                <div className="Order_names">
                                  <span className="O_name">
                                    {item.quantity}x {item.name}
                                  </span>
                                  <span className="O_type">Extra Large</span>
                                </div>
                                <div className="Order_price">
                                  <span className="O_price">₺{item.price}</span>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="totle_Oamount">
                        <div className="O_totlecost">
                          <span className="O_title">Toplam</span>
                          <span className="O_price">₺{calculateTotal()}</span>
                        </div>
                        <a
                          href="/sepet"
                          className="trans red_btn squre-btn hvr-ripple-out"
                          title=""
                        >
                          Sipariş Ver
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="quick_order mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main_quickorder">
                  <h3 className="title">Bizi arayabilirsiniz</h3>
                  <div className="cntct typewriter-effect">
                    <span className="call_desk">
                      <a href="tel:04622301010" id="typewriter_num">0(462) 230 10 10</a>
                      <br />
                      <a href="tel:04622291010" id="typewriter_num">0(462) 229 10 10</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Menu;
