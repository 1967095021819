import { useEffect, useState } from "react";
import Footer from "./components/Footer/Footer";
import Banner from "./components/Banner/Banner";
import Header from "./components/Header/Header";
import firebase, { firestore } from "./firebase";
import Popular from "./components/Popular/Popular";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Main from "./Pages/Main/Main";
import Menu from "./Pages/Main/Menu";
import Cart from "./Pages/Main/Cart";
import Checkout from "./Pages/Main/Checkout";
import Thank from "./Pages/Main/Thank";
import About from "./Pages/Main/About";
import Panel from "./Pages/Panel/Panel";
import Index from "./Pages/Main/Index";
import Print from "./Pages/Panel/Siparisler/Print";
import Login from "./Pages/Panel/Login";



import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <>
      <Router>
        <ToastContainer
          toastStyle={{
            backgroundColor: "#353535",
            color: "white",
          }}
          toastClassName="mb-5"
          position="bottom-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Switch>
          <Route path="/admin/giris" component={Login} />
          <Route path="/admin" component={Panel} />
          <Route path="/print/:id" component={Print} />
          <Route path="/">
            <Index />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;

/*

<li className="pizza active">
                        <a
                          href="http://glorywebsdemo.com/themeforest/html/carveordering/menu.html"
                          className="trans"
                          title="Pizza"
                        >
                          <span className="icon">Pizza</span>
                        </a>
                      </li>
                      <li className="burger1">
                        <a
                          href="http://glorywebsdemo.com/themeforest/html/carveordering/menu.html"
                          className="trans"
                          title="Burgers"
                        >
                          <span className="icon">Burgers</span>
                        </a>
                      </li>
                      <li className="salads">
                        <a
                          href="http://glorywebsdemo.com/themeforest/html/carveordering/menu.html"
                          className="trans"
                          title="Salads"
                        >
                          <span className="icon">Salads</span>
                        </a>
                      </li>
                      <li className="fries">
                        <a
                          href="http://glorywebsdemo.com/themeforest/html/carveordering/menu.html"
                          className="trans"
                          title="Fries"
                        >
                          <span className="icon">Fries</span>
                        </a>
                      </li>
                      <li className="drinks">
                        <a
                          href="http://glorywebsdemo.com/themeforest/html/carveordering/menu.html"
                          className="trans"
                          title="Drinks"
                        >
                          <span className="icon">Drinks</span>
                        </a>
                      </li>



Clients

 <section>
        <div className="client_say">
          <div className="main_client">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="middle_Cpitch">
                    <h2 className="title">Whats Clients Say</h2>
                    <div className="testi_slider">
                      <div>
                        <p className="desc">
                          <span className="double_quat"></span>I ordered Pizza
                          for the first time & I’m so glad I did! I Can’t wait
                          till I order again. Thick, tasty,
                          <br />
                          absolutely delicious Pizza! The gal taking my order
                          was so nice as well.{" "}
                          <span className="double_quat2"></span>
                        </p>
                        <div className="sign">
                          {" "}
                          <span className="name">Paulette Pettry</span>
                          <span className="Desig">CEO</span>{" "}
                        </div>
                      </div>
                      <div>
                        <p className="desc">
                          <span className="double_quat"></span>Wow I have found
                          a great pizza place. They brought me a great pizza and
                          the guy that delivered
                          <br /> my pizza was a cool dude. Thanks for the great
                          pizza. I recommend to everyone.
                          <span className="double_quat2"></span>
                        </p>
                        <div className="sign">
                          {" "}
                          <span className="name">Jhon Walker</span>{" "}
                          <span className="Desig">CEO</span>{" "}
                        </div>
                      </div>
                      <div>
                        <p className="desc">
                          <span className="double_quat"></span>Great pizza.
                          Tastes so fresh and original. Beats any of the chains
                          hands down.
                          <br /> I was so glad to hear Mike’s was opening in
                          Oregon and now it is a wonderful reality.
                          <br /> Give them a try, you won’t be sorry!{" "}
                          <span className="double_quat2"></span>
                        </p>
                        <div className="sign">
                          {" "}
                          <span className="name">Glenn Cook</span>{" "}
                          <span className="Desig">CEO</span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



*/
