import "./assets/css/style.css";
import "./assets/css/slick.css";
import "./assets/css/bootstrap.css";
import "./assets/css/main.css";
import "./assets/css/font.css";
import "./assets/css/extra.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <React.StrictMode>
     <ToastContainer
      toastStyle={{
        backgroundColor: "#353535",
      }}
      toastClassName="mb-5"
      position="bottom-center"
      autoClose={2000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
