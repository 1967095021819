import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Cart = ({ cart, AddToCart, RemoveFromCart, DeleteFromCart }) => {

  const calculateTotal = () => {
    let items = [...cart];
    let total = 0.0;
    items.map((i) => (total += parseFloat(i.price * i.quantity)));
    return total;
  };
  const [selectedOption, setSelectedOption] = useState("delivery");

  const onChangeValue = (e) => {
    setSelectedOption(e.target.value);
  };


  const confirmToast = (text, type = 0) => {
    if (type == 0)
      toast.success(text, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    else if (type == 1) {
      toast.info(text, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (type == 2) {
      toast.error(text, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <>
      <section>
        <div className="main_cart">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main_Ourmenu">
                  <h2 className="title">Sepet</h2>
                </div>
              </div>
              <div className="col-md-12">
                <div className="cart_list">
                  <ul className="inner_cartlist">
                    <li className="header">
                      <div className="main_head">
                        <div className="edit">&nbsp;</div>
                        <div className="pro_name">
                          <h4>Ürün</h4>
                        </div>
                        <div className="pro_qty">
                          <h4>Miktar</h4>
                        </div>
                        <div className="pro_price">
                          <h4>Fiyat</h4>
                        </div>
                        <div className="pro_total">
                          <h4>Toplam</h4>
                        </div>
                      </div>
                    </li>
                    {cart?.map((item) => (
                      <li className="rows ">
                        <div className="edit">
                          <div className="edit_div">
                            <a
                              onClick={() => {
                                DeleteFromCart(item)
                                confirmToast("Sepetten Çıkarıldı!", 1);
                              }}
                              style={{ cursor: "pointer" }}
                              className="trans del"
                              title="Delete"
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                          </div>
                        </div>
                        <div className="pro_name">
                          <div className="products">
                            <div className="pro_img">
                              <img
                                src={item.image}
                                className="absoImg"
                                alt=""
                                style={{ width: '100px', maxHeight: '40px' }}
                              />
                            </div>
                            <div className="pro_descr">
                              <h3 className="pro_title">
                                <a
                                  href="#"
                                  title="Mexican Pizza"
                                  className="trans"
                                >
                                  {item.name}
                                </a>
                              </h3>
                              <p className="pro_dtls" style={{ marginTop: 25 }}>{item.description}</p>
                              {/* <p className="size">
                                <span className="size">Size :</span>Medium
                            </p>*/}
                            </div>
                          </div>
                        </div>
                        <div className="pro_qty">
                          <div className="number-input">
                            <button
                              onClick={() => {
                                AddToCart(item);
                                confirmToast("Sepete Eklendi!");
                              }}
                              className="plus"
                            ></button>
                            <input
                              className="quantity"
                              min="0"
                              name="quantity"
                              value={item.quantity}
                              type="number"
                            />
                            <button
                              onClick={() => {
                                RemoveFromCart(item);
                                confirmToast("Sepetten Çıkarıldı!", 1);
                              }}
                            ></button>
                          </div>
                        </div>
                        <div className="pro_price">
                          <h4>₺{item.price}</h4>
                        </div>
                        <div className="pro_total">
                          <h4>₺{item.price * item.quantity}</h4>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="coupan_block">
                    <form method="post" id="couponform">
                      <div className="coupan_code">
                        <input
                          type="text"
                          name="Coupancode"
                          className="form-control"
                          placeholder="Kupon Kodu"
                        />
                        <button
                          type="submit"
                          disabled
                          className="coupon-btn send trans hvr-ripple-out"
                          name="coupon"
                        >
                          Kupon Ekle
                        </button>
                      </div>
                    </form>
                    <div className="Redio_diltype">
                      <ul>
                        <li>
                          <input
                            type="radio"
                            id="Delivery"
                            name="deliver_type"
                            value="delivery"
                            checked={selectedOption === "delivery"}
                            onChange={onChangeValue}
                          />
                          <label for="Delivery">Teslimat</label>
                        </li>
                        <li>
                          <input
                            type="radio"
                            id="takeaway"
                            name="deliver_type"
                            value="takeaway"
                            checked={selectedOption === "takeaway"}
                            onChange={onChangeValue}
                          />
                          <label for="takeaway">Gel Al</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="input-group" id="meal-note">
                    <div>

                    </div>
                  </div>
                  <div className="checkout">
                    <p className="order_total">
                      <span>Toplam :</span> ₺{calculateTotal()}
                    </p>
                    <div className="btn_checkout">
                      <Link
                        as="a"
                        to={{
                          pathname: "/siparis",
                          state: { selectedOption },
                        }}
                        className="trans red_btn squre-btn hvr-ripple-out"
                        title="Proceed to Checkout"
                      >
                        Sipariş Ver
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
