import { useEffect, useState } from "react";
import { auth, firestore, storage } from "../../../firebase";
import { v4 as uuid } from "uuid";

const Menu = () => {
  const [menu, setMenu] = useState([]);
  const cafe = localStorage.getItem("name");
  const [category, setCategory] = useState();
  const [meal, setMeal] = useState();
  const [items, setItems] = useState();

  useEffect(() => {
    firestore
      .collection("hot-sıfır-bir")
      .doc("menu")
      .onSnapshot((doc) => {
        console.log(doc.data());
        if (doc.exists) {
          let menu = doc.data().Menu;
          menu?.sort((a, b) => {
            if (parseInt(a.sortOrder) < parseInt(b.sortOrder)) return -1;
            else return 1;
          });
          setMenu(menu);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      });
  }, []);

  const [cat, setCat] = useState();
  const [photo, setPhoto] = useState();
  const [sortOrder, setOrder] = useState();
  const [newMeal, setNewMeal] = useState(false);
  const [newCategory, setNewCategory] = useState(false);
  const [activeCategory, setActiveCategory] = useState();

  const update = (id) => {
    let ref = firestore.collection("hot-sıfır-bir").doc("menu");
    firestore.runTransaction((transaction) => {
      return transaction
        .get(ref)
        .then((doc) => {
          let new_menu = doc.data().Menu;

          new_menu.forEach((item, i, new_menu) => {
            if (item.id == id) {
              new_menu[i] = {
                Meals: item.Meals,
                name: cat,
                image: photo,
                id,
                sortOrder,
                active: activeCategory,
              };
            }
          });

          transaction.update(ref, {
            Menu: new_menu,
          });
        })
        .then(() => {
          setCategory(false);
          cleanValues();
        });
    });
  };
  const updateProduct = (id) => {
    let Menu = [...menu];
    Menu.forEach((item, i, Menu) => {
      item.Meals.map((it, ind) => {
        if (it.id == id) {
          Menu[i].Meals[ind] = {
            id,
            description,
            price: price.toString().replace(",", "."),
            name,
            sortOrder: mealSortOrder,
            image: mealPhoto,
            prepTime,
            active: activeMeal,
            isPop: checked,
          };
        }
      });
    });
    firestore
      .collection("hot-sıfır-bir")
      .doc("menu")
      .update({
        Menu,
      })
      .then(() => {
        setCategory();
        cleanValues();
      });
  };

  const deleteCategory = (id) => {
    firestore
      .collection("hot-sıfır-bir")
      .doc("menu")
      .update({
        Menu: menu.filter((m) => m.id != id),
      })
      .then(() => {
        setCategory(false);
        cleanValues();
      });
  };
  const deleteProduct = (id) => {
    let Menu = [...menu];
    Menu.forEach((item, i, Menu) => {
      Menu[i].Meals = item.Meals.filter((i) => i.id != id);
    });
    firestore
      .collection("hot-sıfır-bir")
      .doc("menu")
      .update({
        Menu,
      })
      .then(() => {
        setCategory(false);
        cleanValues();
      });
  };

  const saveCategory = () => {
    firestore
      .collection("hot-sıfır-bir")
      .doc("menu")
      .set(
        {
          Menu: [
            ...menu,
            {
              id: uuid(),
              name: cat,
              Meals: [],
              image: photo,
              sortOrder,
              active: 1,
            },
          ],
        },
        { merge: true }
      )
      .then(() => {
        setNewCategory(false);
        cleanValues();
      });
  };
  const saveProduct = (id) => {
    let ref = firestore.collection("hot-sıfır-bir").doc("menu");
    firestore.runTransaction((transaction) => {
      return transaction
        .get(ref)
        .then((doc) => {
          let new_menu = doc.data().Menu;

          new_menu.forEach((item, i, new_menu) => {
            if (item.id == id) {
              new_menu[i].Meals = [
                ...item.Meals,
                {
                  id: uuid(),
                  name,
                  description,
                  price: price.toString().replace(",", "."),
                  addOptions: [],
                  removeOptions: [],
                  image: mealPhoto,
                  sortOrder: mealSortOrder,
                  prepTime,
                  active: 1,
                  isPop: false,
                },
              ];
            }
          });

          transaction.update(ref, {
            Menu: new_menu,
          });
        })
        .then(() => {
          setCategory();
          cleanValues();
        });
    });
  };

  const cleanValues = () => {
    setCat("");
    setPhoto("");
    setOrder("");
    setName("");
    setPrice("");
    setDescription("");
    setMealPhoto("");
    setMealSortOrder("");
    setPrepTime("");
    setActiveMeal();
    setOpenOpt(false);
    setAddOptions(null);
    setRemoveOptions(null);
  };

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [mealPhoto, setMealPhoto] = useState("");
  const [price, setPrice] = useState("");
  const [mealSortOrder, setMealSortOrder] = useState();
  const [prepTime, setPrepTime] = useState("");
  const [activeMeal, setActiveMeal] = useState(true);

  const [openOpt, setOpenOpt] = useState(false);
  const [addOptions, setAddOptions] = useState();
  const [add, setAdd] = useState(false);
  const [remove, setRemove] = useState(false);
  const [removeOptions, setRemoveOptions] = useState();

  const [addOptName, setAddOptName] = useState("");
  const [addOptPrice, setAddOptPrice] = useState(0);
  const [addOptSortOrder, setAddOptSortOrder] = useState(0);
  const [removeOptName, setRemoveOptName] = useState("");
  const [addOptId, setAddOptId] = useState("");
  const [removeOptId, setRemoveOptId] = useState("");
  const [removeOptSortOrder, setRemoveOptSortOrder] = useState("");
  const [updateOption, setUpdateOption] = useState(false);
  const [updateOptionR, setUpdateOptionR] = useState(false);

  useEffect(() => {
    if (!category) return;
    let chosen = category.Meals;
    chosen?.sort((a, b) => {
      if (parseInt(a.sortOrder) < parseInt(b.sortOrder)) return -1;
      else return 1;
    });
    setItems(chosen);
  }, [add, meal, category, menu]);

  const updateAddOption = (id) => {
    let Menu = [...menu];
    Menu.forEach((category, i1, Menu) => {
      category.Meals.map((item, i2) => {
        if (item.id == meal.id) {
          item.addOptions?.map((opt, i3) => {
            if (opt.id == id) {
              Menu[i1].Meals[i2].addOptions[i3] = {
                id,
                name: addOptName,
                price: addOptPrice.toString().replace(",", "."),
                sortOrder: addOptSortOrder,
              };
            }
          });
        }
      });
    });
    setMenu(Menu);

    firestore
      .collection("hot-sıfır-bir")
      .doc("menu")
      .update({
        Menu,
      })
      .then(() => {
        setAddOptName();
        setAddOptPrice();
        setCategory();
      });
  };
  const createAddOption = (id) => {
    let Menu = [...menu];
    Menu.forEach((item, i, Menu) => {
      item.Meals.map((it, ind) => {
        if (it.id == id) {
          if (it.addOptions && it.addOptions.length > 0) {
            it.addOptions?.push({
              id: uuid(),
              name: addOptName,
              price: addOptPrice.toString().replace(",", "."),
              sortOrder: addOptSortOrder,
            });
          } else {
            it.addOptions = [
              {
                id: uuid(),
                name: addOptName,
                price: addOptPrice.toString().replace(",", "."),
                sortOrder: addOptSortOrder,
              },
            ];
          }
        }
      });
    });
    setMenu(Menu);
    firestore
      .collection("hot-sıfır-bir")
      .doc("menu")
      .update({
        Menu,
      })
      .then(() => {
        setAddOptName();
        setAddOptPrice();
        setAdd(false);
        setCategory();
      });
  };
  const deleteAddOption = (id) => {
    let Menu = [...menu];
    Menu.forEach((item, i, Menu) => {
      item.Meals.map((it, ind) => {
        if (it.id == meal.id) {
          Menu[i].Meals[ind].addOptions = it.addOptions?.filter(
            (opt) => opt.id != id
          );
        }
      });
    });

    setMenu(Menu);

    firestore
      .collection("hot-sıfır-bir")
      .doc("menu")
      .update({ Menu })
      .then(() => {
        setAddOptName();
        setAddOptId();
        setAddOptPrice();
        setCategory();
      });
  };
  const createRemoveOption = (id) => {
    let Menu = [...menu];
    Menu.forEach((item, i, Menu) => {
      item.Meals.map((it, ind) => {
        if (it.id == id) {
          if (it.removeOptions && it.removeOptions.length > 0) {
            it.removeOptions?.push({
              id: uuid(),
              name: removeOptName,
              sortOrder: removeOptSortOrder,
            });
          } else {
            it.removeOptions = [
              {
                id: uuid(),
                name: removeOptName,
                sortOrder: removeOptSortOrder,
              },
            ];
          }
        }
      });
    });
    setMenu(Menu);
    firestore
      .collection("hot-sıfır-bir")
      .doc("menu")
      .update({
        Menu,
      })
      .then(() => {
        setRemoveOptName();
        setRemove(false);
        setCategory();
      });
  };
  const deleteRemoveOption = (id) => {
    let Menu = [...menu];
    Menu.forEach((item, i, Menu) => {
      item.Meals.map((it, ind) => {
        if (it.id == meal.id) {
          Menu[i].Meals[ind].removeOptions = it.removeOptions?.filter(
            (opt) => opt.id != removeOptId
          );
        }
      });
    });
    setMenu(Menu);

    firestore
      .collection("hot-sıfır-bir")
      .doc("menu")
      .update({
        Menu,
      })
      .then(() => {
        setUpdateOptionR(false);
        setCategory();
      });
  };
  const updateRemoveOption = (id) => {
    let Menu = [...menu];
    Menu.forEach((category, i1, Menu) => {
      category.Meals.map((item, i2) => {
        if (item.id == meal.id) {
          item.removeOptions?.map((opt, i3) => {
            if (opt.id == id) {
              Menu[i1].Meals[i2].removeOptions[i3] = {
                id,
                name: removeOptName,
                sortOrder: removeOptSortOrder,
              };
            }
          });
        }
      });
    });
    setMenu(Menu);

    firestore
      .collection("hot-sıfır-bir")
      .doc("menu")
      .update({
        Menu,
      })
      .then(() => {
        setAddOptName();
        setAddOptPrice();
        setCategory();
        setRemove(false);
      });
  };

  const [image, setImage] = useState();
  const uploadImage = () => {
    storage
      .ref(`${image.name}`)
      .put(image)
      .then((task) => {
        return task.ref.getDownloadURL();
      })
      .then((url) => {
        if (newCategory) setPhoto(url);
        if (newMeal) setMealPhoto(url);
        if (category && !meal) setPhoto(url);
        if (category && meal) setMealPhoto(url);
      });
  };

  const [checked, setChecked] = useState();
  const setPopular = () => {
    setChecked(!checked);
  };
  return (
    <div className="row" style={{ marginLeft: "18%" }}>
      <div className="col-3">
        <ul
          className="list-group"
          style={{ cursor: "pointer", fontSize: 15, fontWeight: "bold" }}
        >
          {menu &&
            menu.map((m, i) => (
              <li
                key={i}
                className="list-group-item  list-group-item-action"
                onClick={() => {
                  setCategory(m);
                  setCat(m.name);
                  setPhoto(m.image);
                  setOrder(m.sortOrder);
                  setMeal();
                  setActiveCategory(m.active);
                  setNewCategory(false);
                  setNewMeal(false);
                }}
              >
                <span style={{ fontWeight: "bold" }}>{i + 1}. </span> {m.name}
              </li>
            ))}
        </ul>
        <div className="row">
          <div
            className="btn btn-secondary mt-3 w-50 mx-auto"
            onClick={() => {
              setCategory();
              setMeal();
              setNewCategory(true);
              cleanValues();
            }}
          >
            Yeni Kategori
          </div>
        </div>
      </div>
      <div className="col-3">
        <ul
          className="list-group"
          style={{ cursor: "pointer", fontSize: 15, fontWeight: "bold" }}
        >
          {category &&
            items &&
            items.map((item, i) => (
              <li
                className="list-group-item list-group-item-action"
                key={i}
                onClick={() => {
                  setName(item.name);
                  setDescription(item.description);
                  setPrice(item.price);
                  setMealPhoto(item.image);
                  setMealSortOrder(item.sortOrder);
                  setMeal(item);
                  setPrepTime(item.prepTime);
                  setActiveMeal(item.active);
                  setNewMeal(false);
                  setOpenOpt(true);
                  setAddOptions(item.addOptions);
                  setRemoveOptions(item.removeOptions);
                  setUpdateOption(false);
                  setUpdateOptionR(false);
                  setRemove(false);
                  setChecked(item.isPop || false);
                }}
              >
                <span style={{ fontWeight: "bold" }}>{i + 1}. </span>{" "}
                {item.name}
              </li>
            ))}
        </ul>
        <div className="row">
          {category && (
            <div
              className="btn btn-secondary mt-3 w-50 mx-auto"
              onClick={() => {
                setNewMeal(true);
                cleanValues();
                setMeal();
                setUpdateOption(false);
              }}
            >
              Yeni Ürün
            </div>
          )}
        </div>
      </div>

      <div className="col-3">
        {newCategory && (
          <form>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              Yeni Kategori
            </p>
            <div className="form-group">
              <label for="exampleFormControlInput1">Kategori Adı</label>
              <input
                type="text"
                className="form-control"
                value={cat}
                onChange={(e) => setCat(e.target.value)}
              />
            </div>
            <div className="form-group row">
              <div className="col">
                <label for="exampleFormControlInput1">Fotoğraf Yükle</label>
                <input
                  type="file"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                />
              </div>

              <div className="col btn btn-primary mt-3" onClick={uploadImage}>
                Yükle
              </div>
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Fotoğraf</label>
              <input
                type="text"
                className="form-control"
                value={photo}
                onChange={(e) => setPhoto(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Sıralama</label>
              <input
                type="text"
                className="form-control"
                value={sortOrder}
                onChange={(e) => setOrder(e.target.value)}
              />
            </div>

            <div className="row">
              <div
                className="btn btn-primary w-25 mx-auto mt-5"
                onClick={() => saveCategory()}
              >
                Kaydet
              </div>
            </div>
          </form>
        )}

        {category && !meal && !newMeal && (
          <form>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              {category.name} Güncelle
            </p>
            <div className="form-group">
              <label for="exampleFormControlInput1">Kategori Adı</label>
              <input
                type="text"
                className="form-control"
                value={cat}
                placeholder={category.name}
                onChange={(e) => setCat(e.target.value)}
              />
            </div>
            <div className="form-group row">
              <div className="col">
                <label for="exampleFormControlInput1">Fotoğraf Yükle</label>
                <input
                  type="file"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                />
              </div>

              <div className="col btn btn-primary mt-3" onClick={uploadImage}>
                Yükle
              </div>
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Fotoğraf</label>
              <input
                type="text"
                className="form-control"
                value={photo}
                placeholder={category.photo}
                onChange={(e) => setPhoto(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Sıralama</label>
              <input
                type="text"
                className="form-control"
                value={sortOrder}
                placeholder={category.sortOrder}
                onChange={(e) => setOrder(e.target.value)}
              />
            </div>

            <div className="row">
              <div className="btn-group">
                <div
                  className="btn btn-secondary w-25 mx-auto mt-5"
                  onClick={() => update(category.id)}
                >
                  Güncelle
                </div>
                <div
                  className="btn btn-danger w-25 mx-auto mt-5"
                  onClick={() => deleteCategory(category.id)}
                >
                  Sil
                </div>
                {activeCategory == 1 ? (
                  <div
                    className="btn btn-danger mx-auto mt-5"
                    style={{ width: "18%" }}
                    onClick={() => setActiveCategory(0)}
                  >
                    Pasif Et
                  </div>
                ) : (
                  <div
                    className="btn btn-success mx-auto mt-5"
                    style={{ width: "18%" }}
                    onClick={() => setActiveCategory(1)}
                  >
                    Aktif Et
                  </div>
                )}
              </div>
            </div>
          </form>
        )}

        {category && (meal || newMeal) && (
          <form>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              {newMeal ? "Ürün Ekle" : `${meal.name} Güncelle`}
            </p>
            <div className="form-group">
              <label for="exampleFormControlInput1">Ürün Adı</label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Fiyatı</label>
              <input
                type="text"
                className="form-control"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            <div className="form-group row">
              <div className="col">
                <label for="exampleFormControlInput1">Fotoğraf Yükle</label>
                <input
                  type="file"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                />
              </div>

              <div className="col btn btn-primary mt-3" onClick={uploadImage}>
                Yükle
              </div>
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Fotoğraf</label>
              <input
                type="text"
                className="form-control"
                value={mealPhoto}
                onChange={(e) => setMealPhoto(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Sıralama</label>
              <input
                type="text"
                className="form-control"
                value={mealSortOrder}
                onChange={(e) => setMealSortOrder(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label for="exampleFormControlInput1">Hazırlanma Süresi</label>
              <input
                type="text"
                className="form-control"
                value={prepTime}
                onChange={(e) => setPrepTime(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label for="exampleFormControlTextarea1">Açıklama</label>

              <textarea
                className="form-control"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="3"
              ></textarea>
            </div>

            {meal ? (
              <div className="row mb-5" style={{ width: "120%" }}>
                <div className="btn-group mx-auto" role="group">
                  <div
                    className="btn btn-secondary  mx-auto mt-5"
                    onClick={() => updateProduct(meal.id)}
                  >
                    Güncelle
                  </div>
                  <div
                    className="btn btn-danger mx-auto mt-5"
                    style={{
                      borderRight: "1px solid black",
                      borderLeft: "1px solid black",
                    }}
                    onClick={() => deleteProduct(meal.id)}
                  >
                    Sil
                  </div>
                  {activeMeal == 1 ? (
                    <div
                      className="btn btn-danger mx-auto mt-5"
                      onClick={() => setActiveMeal(0)}
                    >
                      Pasif Et
                    </div>
                  ) : (
                    <div
                      className="btn btn-success mx-auto mt-5"
                      onClick={() => setActiveMeal(1)}
                    >
                      Aktif Et
                    </div>
                  )}

                  <div
                    className={
                      checked == true
                        ? "btn btn-success mx-auto mt-5"
                        : "btn btn-danger mx-auto mt-5"
                    }
                    onClick={() => setPopular()}
                  >
                    Popüler
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div
                  className="btn btn-secondary w-25 mx-auto mt-5"
                  onClick={() => saveProduct(category.id)}
                >
                  Ekle
                </div>
                <div></div>
              </div>
            )}
          </form>
        )}
      </div>

      <div className="col-3">
        {category && items && meal && openOpt && (
          <>
            {/*<ul
              className="list-group"
              style={{ cursor: "pointer", fontSize: 15, fontWeight: "bold" }}
            >
              {addOptions
                ?.sort((a, b) => {
                  if (parseFloat(a.sortOrder) > parseFloat(b.sortOrder))
                    return 1;
                  else return -1;
                })
                .map((item, i) => (
                  <li
                    className="list-group-item list-group-item-action"
                    key={i}
                    onClick={() => {
                      setAddOptName(item.name);
                      setAddOptPrice(item.price);
                      setAddOptId(item.id);
                      setAddOptSortOrder(item.sortOrder);

                      setUpdateOption(true);
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>{i + 1}. </span>{" "}
                    {item.name}
                  </li>
                ))}
            </ul>
            <div className="row">
              <div
                className="btn btn-secondary mt-3 w-50 mx-auto"
                onClick={() => {
                  setAdd(true);
                  setUpdateOption();
                }}
              >
                Ekstra Ekle
              </div>
              {updateOption && openOpt && (
                <div
                  className="btn btn-danger mt-3 w-25 mx-auto"
                  onClick={() => {
                    deleteAddOption(addOptId);
                  }}
                >
                  Sil
                </div>
              )}
                </div>
            <ul
              className="list-group mt-5"
              style={{ cursor: "pointer", fontSize: 15, fontWeight: "bold" }}
            >
              {removeOptions
                ?.sort((a, b) => {
                  if (parseFloat(a.sortOrder) > parseFloat(b.sortOrder))
                    return 1;
                  else return -1;
                })
                .map((item, i) => (
                  <li
                    className="list-group-item list-group-item-action"
                    key={i}
                    onClick={() => {
                      setRemoveOptName(item.name);
                      setRemoveOptId(item.id);
                      setRemoveOptSortOrder(item.sortOrder);

                      setUpdateOptionR(true);
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>{i + 1}. </span>{" "}
                    {item.name}
                  </li>
                ))}
            </ul>
            <div className="row">
              {category && (
                <div
                  className="btn btn-secondary mt-3 w-50 mx-auto "
                  onClick={() => {
                    setRemove(true);
                    setRemoveOptName();
                    setRemoveOptSortOrder();
                    setUpdateOptionR(false);
                  }}
                >
                  Çıkarılacak Ürün Ekle
                </div>
              )}
              {updateOptionR && (
                <div
                  className="btn btn-danger w-25 mt-3 mx-auto "
                  onClick={() => {
                    deleteRemoveOption();
                  }}
                >
                  Sil
                </div>
              )}
            </div>*/}

            {/*addOptions && openOpt && updateOption && (
              <div className="row mt-5">
                <form>
                  <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    {addOptName} Düzenle
                  </p>

                  <div className="form-group">
                    <label for="exampleFormControlInput1">İsim</label>
                    <input
                      type="text"
                      className="form-control"
                      value={addOptName}
                      onChange={(e) => setAddOptName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleFormControlInput1">Ücreti</label>
                    <input
                      type="text"
                      className="form-control"
                      value={addOptPrice}
                      onChange={(e) => setAddOptPrice(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleFormControlInput1">Sıralaması</label>
                    <input
                      type="text"
                      className="form-control"
                      value={addOptSortOrder}
                      onChange={(e) => setAddOptSortOrder(e.target.value)}
                    />
                  </div>

                  <div className="row">
                    <div
                      className="btn btn-primary w-25 mx-auto mt-5"
                      onClick={() => {
                        updateAddOption(addOptId);
                      }}
                    >
                      Kaydet
                    </div>
                  </div>
                </form>
              </div>
                    )*/}
            {/*updateOptionR && (
              <div className="row mt-5">
                <form>
                  <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    {removeOptName} Düzenle
                  </p>

                  <div className="form-group">
                    <label for="exampleFormControlInput1">İsim</label>
                    <input
                      type="text"
                      className="form-control"
                      value={removeOptName}
                      onChange={(e) => setRemoveOptName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleFormControlInput1">Sıralaması</label>
                    <input
                      type="text"
                      className="form-control"
                      value={removeOptSortOrder}
                      onChange={(e) => setRemoveOptSortOrder(e.target.value)}
                    />
                  </div>

                  <div className="row">
                    <div
                      className="btn btn-primary w-25 mx-auto mt-5"
                      onClick={() => {
                        updateRemoveOption(removeOptId);
                      }}
                    >
                      Kaydet
                    </div>
                  </div>
                </form>
              </div>
                    )*/}

            {/*add && (
              <div className="row mt-5 w-75 mx-auto">
                <form>
                  <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    Yeni Seçenek Ekle
                  </p>

                  <div className="form-group">
                    <label for="exampleFormControlInput1">İsim</label>
                    <input
                      type="text"
                      className="form-control"
                      value={addOptName}
                      onChange={(e) => setAddOptName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleFormControlInput1">Ücreti</label>
                    <input
                      type="text"
                      className="form-control"
                      value={addOptPrice}
                      onChange={(e) => setAddOptPrice(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleFormControlInput1">Sıralaması</label>
                    <input
                      type="text"
                      className="form-control"
                      value={addOptSortOrder}
                      onChange={(e) => setAddOptSortOrder(e.target.value)}
                    />
                  </div>

                  <div className="row">
                    <div
                      className="btn btn-primary w-50 mx-auto mt-5"
                      onClick={() => createAddOption(meal.id)}
                    >
                      Kaydet
                    </div>
                  </div>
                </form>
              </div>
            )*/}
            {/*remove && (
              <div className="row mt-5 w-75 mx-auto">
                <form>
                  <p style={{ textAlign: "center", fontWeight: "bold" }}>
                    Çıkarılacak Ürün Ekle
                  </p>

                  <div className="form-group">
                    <label for="exampleFormControlInput1">İsim</label>
                    <input
                      type="text"
                      className="form-control"
                      value={removeOptName}
                      onChange={(e) => setRemoveOptName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleFormControlInput1">Sıralaması</label>
                    <input
                      type="text"
                      className="form-control"
                      value={removeOptSortOrder}
                      onChange={(e) => setRemoveOptSortOrder(e.target.value)}
                    />
                  </div>

                  <div className="row">
                    <div
                      className="btn btn-primary w-50 mx-auto mt-5"
                      onClick={() => createRemoveOption(meal.id)}
                    >
                      Kaydet
                    </div>
                  </div>
                </form>
              </div>
            )*/}
          </>
        )}
      </div>
    </div>
  );
};

export default Menu;
