import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popular from "../../components/Popular/Popular";
import { useHistory } from "react-router-dom";

const Main = ({
  menu,
  categories,
  cart,
  AddToCart,
  RemoveFromCart,
  popular,
}) => {
  const [selected, Select] = useState(useLocation().state?.selected || null);
  const [items, setItems] = useState();

  const [popup, setPopup] = useState({ display: "none", opacity: 0 });
  useEffect(() => {
    if (selected) {
      setItems(menu?.filter((m) => m.id == selected?.id)[0]);
    } else {
      Select(menu[0]);
      setItems(menu[0]);
    }
  }, [menu]);

  useEffect(() => {
    setItems(menu?.filter((m) => m.id == selected?.id)[0]);
  }, [selected]);

  const calculateTotal = () => {
    let items = [...cart];
    let total = 0.0;
    items.map((i) => (total += parseFloat(i.price * i.quantity)));
    return total;
  };

  const confirmToast = (text, type = 0) => {
    if (type == 0)
      toast.success(text, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    else if (type == 1) {
      toast.info(text, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type == 2) {
      toast.error(text, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const history = useHistory();
  return (
    <>
      <div className="H_banner home" id="home-desktop">
        <div className="main_pitch">
          {/*<h1 className="page_title">Acılı bir Adana Hikayesi</h1>
          <h3 className="cntct">
            Hot Sıfır Bir
             <span className="call_desk">
              <a href="tel:03624381009" id="typewriter_num"></a>
              <br />
              <a href="tel:03624381009" id="typewriter_num1"></a>
            </span>

          </h3>*/}
        </div>
      </div>
      <div className="H_banner-mobile" id="home-mobile">
        <div className="main_pitch">
          <h1 className="page_title">Acılı bir Adana Hikayesi</h1>
          <h3
            className="cntct"
            onClick={() => history.push("/menu")}
            style={{ fontSize: 16 }}
          >
            {"> Menü'ye Bakın <"}
          </h3>
        </div>
      </div>
      <div className="bottom_pitch x1">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="main_bpitch p_main" style={{ fontSize: 22 }}>
                <ul>
                  {categories?.map((cat) => (
                    <li className={cat.className}>
                      <Link
                        to="/menu"
                        className="trans"
                        title={cat.name}
                        onClick={() => {
                          Select(cat);
                        }}
                      >
                        <span className="icon">{cat.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Banner />

      {/* <Popular popular={popular} />  */}
      <>
        {/* <div className="options-popup" style={popup}>
        <div class="popup">f
          <a
            class="close"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPopup({ display: "none" });
            }}
          >
            &times;
          </a>
        </div>
          </div> */}

        {/*<div className="H_banner">
        <div className="main_pitch">
          <h1 className="page_title">{selected?.name}</h1>
        </div>
        </div>*/}

        <Popular
          popular={popular}
          cart={cart}
          AddToCart={AddToCart}
          RemoveFromCart={RemoveFromCart}
        />

        <section>
          <div className="quick_order mt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="main_quickorder">
                    <h3 className="title">Bizi arayabilirsiniz</h3>
                    <div className="cntct typewriter-effect">
                      <span className="call_desk">
                        <a href="tel:04622301010">0(462) 230 10 10</a>
                        <br />
                        <a href="tel:04622291010">0(462) 229 10 10</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <div className="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3011.1638417957797!2d39.68489025924681!3d40.99978697361666!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40643dd48f2ca22b%3A0xfc90702ac9027976!2sHOT%20SIFIR%20B1R%20ADANA%20KEBAP%20ve%20C%C4%B0%C4%9EER!5e0!3m2!1str!2str!4v1652537040257!5m2!1str!2str" width="800" height="600" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </>
  );
};

export default Main;

/*
<iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4236.466792499519!2d36.263502117592694!3d41.338562785320306!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3f14f64007c49c30!2sD%C4%B0SCO%20BURGER!5e0!3m2!1str!2str!4v1638211505715!5m2!1str!2str"
          width={600}
          height={450}
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
        ></iframe>
*/
