import React from "react";

const Thank = () => {
  return (
    <>
      <header id="Header">
        <div className="main_header inner_page">
          <div class="H_banner">
            <div class="main_pitch">
              <h1 class="page_title">Teşekkürler</h1>
            </div>
          </div>
        </div>
      </header>

      <div id="complated">
        <div class="main_complated">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="inner_complated">
                  <div class="img_cmpted">
                    <img
                      src="http://glorywebsdemo.com/themeforest/html/carveordering/images/cmpted_logo.png"
                      alt=""
                    />
                  </div>
                  <p class="dsc_cmpted">
                    Abone olduğunuz için teşekkür ederiz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thank;

/*

<div class="btn_cmpted">
                    <a
                      href="#"
                      class="trans red_btn hvr-float-shadow"
                      title="Go To Tracker"
                    >
                      Go To Tracker{" "}
                    </a>
                  </div>
                  */
