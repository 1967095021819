import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import firebase, { auth, firestore } from "../../firebase";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const login = (e) => {
    e.preventDefault();
    try {
      firebase
        .auth()
        .signInWithEmailAndPassword(username + "@dba.com", password)
        .then((userCredential) => {
          // Signed in
          history.push("/admin");
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(error);
        });
    } catch (err) {
      alert("Hatalı Giriş");
    }
  };
  return (
    <section id="login">
      <div class="login_section">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="main_login" style={{ marginLeft: "30%" }}>
                <div class="login_left col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="login_box">
                    <h2 class="title">Giriş Yap</h2>
                    <form id="loginform">
                      <div class="login_form">
                        <ul>
                          <li>
                            <label for="EmailAddress">Kullanıcı Adı</label>
                            <input
                              class="form-control"
                              id="EmailAddress"
                              name="emailaddress"
                              title="Email Address"
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                            />
                          </li>
                          <li>
                            <label for="Password">Şifre</label>
                            <input
                              class="form-control"
                              title="Password"
                              id="Password"
                              name="password"
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </li>
                          <li class="half checkbox">
                            <input
                              id="check4"
                              type="checkbox"
                              name="check"
                              value="Bike"
                            />
                          </li>

                          <li>
                            <button
                              type="submit"
                              onClick={login}
                              class="loginbutton red_btn trans squre-btn hvr-ripple-out"
                              name="Login"
                            >
                              Giriş Yap
                            </button>
                          </li>
                        </ul>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
