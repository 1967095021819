import { useEffect, useState } from "react";
import "../../../assets/css/order.css";
import { firestore } from "../../../firebase";
import { confirmAlert } from "react-confirm-alert";
import { useHistory, Redirect } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";

const Order = ({
  order,
  approveOrder,
  cancelOrder,
  deliverOrder,
  notActive,
  active,
}) => {
  console.log(order);
  const history = useHistory();
  useEffect(() => {
    if (active != null && active == false) {
      notActive(order.id);
    }
  }, []);

  const printOrder = () => {
    history.push({ pathname: `/print/${order.id}` });
  };
  const calculateTotal = () => {
    let items = [...order.cart];
    let total = 0.0;
    items.map((i) => (total += parseFloat(i.price * i.quantity)));
    return total;
  };
  return (
    <div
      className="col-sm-6 col-lg-3"
      style={{ display: !active ? "none" : "" }}
    >

      <div
        className="card text-center"
        style={{ minHeight: 300, color: "black" }}
      >
        <div
          className="card-body"
          style={{ background: order?.status == "1" ? "#eeeeee" : "" }}
        >
          <div>
            {order?.cart?.map((o) => {
              return (
                <>
                  <h5 className="card-title" style={{ textAlign: "left" }}>
                    {o.quantity} x {o.name}
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {o.quantity * o.price} TL
                    </span>
                  </h5>
                  {o.checkedOptions && (
                    <p>Ekstralar: {o.checkedOptions?.map((opt) => opt.name)}</p>
                  )}
                  {o.removedOptions && (
                    <p>
                      Çıkarılacaklar: {o.removedOptions?.map((opt) => opt.name)}
                    </p>
                  )}

                  {o.mealNote && <h5 className="card-title"> {o.mealNote} </h5>}
                </>
              );
            })}
            Toplam Tutar:
            <p className="card-text " style={{ fontWeight: "bold" }}>
              {" "}
              {calculateTotal()} TL
            </p>
            {order?.note && (
              <h5 className="card-title" style={{ fontWeight: "bold" }}>
                Not: {order.note}{" "}
              </h5>
            )}
          </div>
          <div style={{ fontWeight: 600 }}>
            <p className="card-text">
              {order?.firstname + " " + order?.lastname}
            </p>
            <p className="card-text">{order?.phone}</p>
            <p className="card-text">{order?.address1}</p>
            <p className="card-text">{order?.address2}</p>
            <p className="card-text">{order?.notes}</p>
            <p
              className="card-text"
              style={{ fontWeight: "bold", fontSize: 20, marginBottom: 10 }}
            >
              {order?.deliveryType == "delivery" ? "Teslimat" : "Gel-Al"}
            </p>
          </div>
          <div className="row">
            <div className="col">
              <a
                href="#"
                className="btn btn-dark"
                onClick={() => {
                  printOrder();
                }}
              >
                Yazdır
              </a>
            </div>
            {order?.status == 0 && (
              <>
                <div className="col">
                  <a
                    href="#"
                    className="btn btn-dark"
                    onClick={() => {
                      confirmAlert({
                        message: "Onaylıyor musunuz?",
                        buttons: [
                          {
                            label: "Evet",
                            onClick: () => {
                              approveOrder(order.id);
                            },
                          },
                          {
                            label: "Hayır",
                          },
                        ],
                      });
                    }}
                  >
                    Onayla
                  </a>
                </div>
              </>
            )}
          </div>
          {order.status == 0 ? (
            <div className="row w-50 mx-auto mt-2">
              <div
                className="btn btn-danger"
                onClick={() => {
                  confirmAlert({
                    message: "Onaylıyor musunuz?",
                    buttons: [
                      {
                        label: "Evet",
                        onClick: () => {
                          cancelOrder(order.id);
                        },
                      },
                      {
                        label: "Hayır",
                      },
                    ],
                  });
                }}
              >
                İptal Et
              </div>
            </div>
          ) : order.deliveryType == "delivery" ? (
            <div className="row w-50 mx-auto mt-2">
              <div
                className="btn btn-secondary"
                onClick={() => {
                  confirmAlert({
                    message: "Onaylıyor musunuz?",
                    buttons: [
                      {
                        label: "Evet",
                        onClick: () => {
                          deliverOrder(order.id, order.deliveryType);
                        },
                      },
                      {
                        label: "Hayır",
                      },
                    ],
                  });
                }}
              >
                Yola Çıkar
              </div>
            </div>
          ) : (
            <div className="row w-50 mx-auto mt-2">
              <div
                className="btn btn-secondary"
                onClick={() => {
                  confirmAlert({
                    message: "Onaylıyor musunuz?",
                    buttons: [
                      {
                        label: "Evet",
                        onClick: () => {
                          deliverOrder(order.id, order.deliveryType);
                        },
                      },
                      {
                        label: "Hayır",
                      },
                    ],
                  });
                }}
              >
                Teslim Edildi
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Order;
